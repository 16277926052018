import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cookies from 'js-cookie';
import Head from '@/components/head/index';
import TimeScreeningse from '@/components/timeScreeningse';
import { sendmsg } from '@/api/login';
import { globalBus } from '@/utils/globalBus';
import { MsgList, MsgUpdate } from '@/api/updateUserInfo/Msg';
export default {
  data: function data() {
    return {
      radio: 1,
      title: '消息管理',
      value: '',
      tableData: [],
      Alltableoptions: [],
      total: 0,
      visdisplay: false,
      cname: '',
      //客户名字
      start_time: '',
      end_time: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      status: '',
      value1: '',
      // NumberEs: 2,
      msgStatus: '2',
      msgType: '',
      msgoptions: [{
        value: '1',
        label: '已读'
      }, {
        value: '2',
        label: '未读'
      }, {
        value: '4',
        label: '全部'
      }],
      typeoptions: {
        1: '共享通知',
        2: '分配通知',
        3: '跟进提醒',
        4: '转移通知',
        5: '跟进通知',
        6: '缴费通知',
        7: '撞单通知',
        8: '主管审核通知',
        9: '财务审核通知',
        10: '订单通过通知',
        11: '订单驳回通知'
      },
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false
      // 系统教程
    };
  },
  //状态
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        1: 'success',
        0: 'info'
      };
      return statusMap[status];
    }
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList();
    } else {}
  },
  computed: {},
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse
  },
  methods: {
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    screening: function screening(item) {
      var _this = this;
      this.value1 = '';
      this.start_time = item[0];
      this.end_time = item[1];
      this.$nextTick(function () {
        _this.GetList();
      });
    },
    GetList: function GetList() {
      var _this2 = this;
      MsgList({
        type: this.type,
        paginate: this.query.pagesize,
        page: this.query.page,
        status: this.status,
        cname: this.cname,
        start_time: this.start_time,
        end_time: this.end_time
      }).then(function (respomse) {
        _this2.tableData = respomse.data.data;
        _this2.query.total = respomse.data.total;
        // localStorage.setItem('shuliang',this.total);
      });
      // if (val == 9) {
      //     this.visdisplay = true;
      // } else {
      //     this.visdisplay = false;
      // }
    },
    //
    GetListbtn: function GetListbtn(val) {
      var _this3 = this;
      this.type = val;
      this.$nextTick(function () {
        _this3.GetList();
      });
    },
    //时间戳查询
    DataTime: function DataTime() {
      var _this4 = this;
      if (this.value1 == null) {
        this.start_time = '';
        this.end_time = '';
        this.$nextTick(function () {
          _this4.GetList();
        });
      } else {
        var s_time = this.value1[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.value1[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this4.GetList();
        });
      }
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this5.GetList();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this6 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this6.GetList();
      });
    },
    //查询
    handleFilter: function handleFilter() {
      var _this7 = this;
      this.$nextTick(function () {
        _this7.GetList();
      });
    },
    handleMsg: function handleMsg(val) {
      var _this8 = this;
      this.status = val;
      this.$nextTick(function () {
        _this8.GetList();
      });
    },
    clearMsg: function clearMsg() {
      var _this9 = this;
      this.status = 4;
      this.$nextTick(function () {
        _this9.GetList();
      });
    },
    handleType: function handleType(val) {
      var _this10 = this;
      this.type = val;
      this.$nextTick(function () {
        _this10.GetList();
      });
    },
    //选中操作
    selsChange: function selsChange(val) {
      this.Alltableoptions = val;
      // if (val.length > 1) {
      //     this.$refs.tb.clearSelection();
      //     this.$refs.tb.toggleRowSelection(val.pop());
      // }
    },
    //部分复选框禁用
    selectable: function selectable(row, index) {
      if (row.status == 1) {
        return false;
      } else {
        return true;
      }
    },
    //已读
    // Read() {
    //     if (this.Alltableoptions.length == 1) {
    //         this.$confirm('确认更改为已读吗?', '提示', {
    //             type: 'warning'
    //         })
    //             .then(() => {
    //                 this.Alltableoptions.forEach((item, index) => {
    //                     let para = { ids: item.id };
    //                     if (item.status == 1) {
    //                         this.$message('此项已完成已读');
    //                     } else {
    //                         MsgUpdate(para).then(res => {
    //                             this.$notify({
    //                                 message: '状态更改成功',
    //                                 type: 'success'
    //                             });
    //                             this.$nextTick(() => {
    //                                 //获取列表方法
    //                                 this.GetList();
    //                             });
    //                         });
    //                     }
    //                 });
    //             })
    //             .catch(() => {});
    //     } else {
    //         MsgList({ type: this.type, status: '1', paginate: this.paginate, page: this.query.page }).then(respomse => {
    //             this.tableData = respomse.data.data;
    //             this.total = this.tableData.length;
    //         });
    //         // this.type = this.type;
    //         // this.status = '1';
    //         // this.$nextTick(() => {
    //         //     this.GetList();
    //         // });
    //     }
    // },
    // //未读
    // Unread() {
    //     this.status = '2';
    //     MsgList({ type: this.type, status: '2', paginate: this.paginate }).then(respomse => {
    //         this.tableData = respomse.data.data;
    //         this.total = respomse.data.tatol;
    //     });
    //     // this.$nextTick(() => {
    //     //     this.GetList();
    //     // });
    // },
    handleReaded: function handleReaded() {
      var _this11 = this;
      if (this.Alltableoptions.length == 0) {
        this.$message({
          message: '请选择要操作的数据！',
          type: 'warning'
        });
      } else {
        this.$confirm('确认更改为已读吗?', '提示', {
          type: 'warning'
        }).then(function () {
          var para = _this11.Alltableoptions.map(function (item) {
            return item.id;
          }).join(','); //字符串逗号隔开
          MsgUpdate({
            ids: para
          }).then(function (res) {
            localStorage.setItem('shuliang', res.data.unread_num);
            _this11.$notify({
              message: '状态更改成功',
              type: 'success',
              duration: '3000'
            });
            _this11.$nextTick(function () {
              //获取列表方法
              _this11.GetList();
            });
          });
        }).catch(function () {});
      }
      sendmsg().then(function (res) {
        _this11.$store.commit('SET_Msgid', res.data.unread_num);
      });
    }
  }
};